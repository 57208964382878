import { useEffect, useState } from 'react';
import { LGSChart1, LGSChart2, LGSChart3, LGSChart4, LGSChart5, CartNPurchaseChart, FunnelChart } from './DashboardCharts';
import dashboardStyle from '../../style/dashboard.module.css';
import Dropdown from '../Units/Dropdown';
import DateRangePicker from '../Units/DateRangePicker';
import { fetchDashboardData } from '../../apis/api/indicators/api';
import { getFormattedNumberValue, getFormattedDecimalValue, getFormattedDate } from '../../apis/api/utils';
import { ConsoleFooterSpacer, ConsoleFooter } from '../Units/ConsoleFooter.jsx';
const Dashboard = () => {
    const shopId = sessionStorage.getItem('sid');
    const [startDate, setStartDate] = useState(getFormattedDate(Date.now() - 7 * 24 * 60 * 60 * 1000));
    const [endDate, setEndDate] = useState(getFormattedDate());
    const channels = [{ title: '전체', value: 'all' }, { title: '모바일', value: 'mobile' }, { title: '웹', value: 'web' }];
    const [selectedChannels, setSelectedChannels] = useState([channels[0]]);
    const [channelValues, setChannelValues] = useState([true, true, true]);
    const [isLoading, setIsLoading] = useState(true);
    const [dashboardData, setDashboardData] = useState();
    const [noData, setNoData] = useState([false, false, false]);

    const dbLineGraphLTextContents = ['총 장바구니 담기 건수', '총 장바구니 담기 금액', '총 구매건수', '총 구매 금액'];
    const dbBarGraphLTextContents = ['채팅창 유입률', '상품 클릭률', '상품 구매율'];

    const updateDashboardData = (key, newData) => {
        const deepCopiedData = structuredClone(newData);

        setDashboardData((prev) => ({
            ...prev,
            [key]: deepCopiedData,
        }));
        /* if (key === 'lineL') {
            setDbLineGraphLTextData(deepCopiedData[0].textDatas);
            console.log(dbLineGraphLTextData);
        } else if (key === 'barL') {
            setDbBarGraphLTextData(deepCopiedData[0].textDatas);
            console.log(dbBarGraphLTextData);
        } */
    }

    useEffect(() => {
        const channelConcat = selectedChannels.map(channel => channel.value).join(',');
        fetchNewSettingData(startDate, endDate, channelConcat, shopId);
    }, []);

    useEffect(() => {
        console.log("noData", noData);
        console.log("dashboardData", dashboardData);
    }, [noData, dashboardData]);

    useEffect(() => {
        const channelConcat = selectedChannels.map(channel => channel.value).join(',');
        fetchNewSettingData(startDate, endDate, channelConcat, shopId);
    }, [startDate, endDate, selectedChannels]);

    const fetchNewSettingData = (startDate, endDate, channel, shopId) => {
        setIsLoading(true);
        if (channel.length === 0) {     // 채널 선택 없음
            setNoData(() => [true, true, true]);
            setIsLoading(false);
            return;
        }
        const formattedStartDate = getFormattedDate(startDate);
        if (!endDate) return;
        const formattedEndDate = getFormattedDate(endDate);
        fetchDashboardData(formattedStartDate, formattedEndDate, channel, shopId)
            .then(res => {
                const newNoData = [false, false, false];
                if ((res[0].length===0) || (res[0][0].series.data.length === 0)) newNoData[0] = true;
                updateDashboardData('lineS', res[0]);
                if ((res[1].length === 0) || (res[1][0].multiSeries[0].data.length === 0)) {
                    newNoData[1] = true;
                    updateDashboardData('lineL', res[1]);
                } else {
                    newNoData[1] = false;
                    // check missing dates first
                    const filledMultiseries = fillMultiSeriesMissingDates(res[1][0].multiSeries);
                    const filledLineL = [{ ...res[1][0], multiSeries: filledMultiseries}]
                    updateDashboardData('lineL', filledLineL);
                }
                if ((res[2].length === 0) || (res[2][0].series.data.length === 0) || (res[2][0].textDatas[0].value === null)) {
                    newNoData[2] = true;
                }
                /* 퍼널 차트 에러 대비: 모든 데이터가 5 이하인 경우 로그 처리 모듈 에러 발생, 임시로 No Data 처리 */
                const tooSmallValues = res[2][0].series.data.filter(data => data.y < 6);
                if (tooSmallValues.length >= 5) {
                    newNoData[2] = true;
                }
                updateDashboardData('barL', res[2]);
                setNoData(newNoData);
                setIsLoading(false);
            })
    }
 
    return (
        <div className={dashboardStyle.Dashboard__Container}>
            {/* dashboard setting block */}
            <div className={dashboardStyle.Setting__Wrapper}>
                <div className={dashboardStyle.Setting__Date}>
                    <p className='h6 fc-gray-600'>날짜 설정</p>
                    <div className='spacing-8'/>
                    <DateRangePicker 
                        startDate={startDate} 
                        endDate={endDate} 
                        setStartDate={setStartDate} 
                        setEndDate={setEndDate} 
                    /> 
                </div>
                <div className='spacing-24'/>
                <div className={dashboardStyle.Setting__Channel}>
                    <p className='h6 fc-gray-600'>유입 채널</p>
                    <div className='spacing-8' />
                    <Dropdown 
                        options={channels} 
                        optionValues={channelValues} 
                        setOptionValues={setChannelValues} 
                        selectedChannels={selectedChannels} 
                        setSelectedChannels={setSelectedChannels} 
                    />
                </div>
            </div>
            <div className='spacing-24'/>
            {/* db line graph small block */}
            <div className={dashboardStyle.DBLineGraphS__Container}>
                <div className={dashboardStyle.DBLineGraphS__Wrap}>
                    <div className={dashboardStyle.DBLineGraphS__Body} style={{width: '342px'}}>
                        <LGSChart1 
                            isLoading={isLoading} 
                            noData={noData[0]} 
                            data={dashboardData?.lineS} 
                            dbLineGraphSIdx={0} 
                            startDate={startDate} 
                            endDate={endDate}
                        />
                    </div>
                    <div className='spacing-8'/>
                    <div className={dashboardStyle.DBLineGraphS__Body}>
                        <LGSChart2 
                            isLoading={isLoading} 
                            noData={noData[0]} 
                            data={dashboardData?.lineS} 
                            dbLineGraphSIdx={1} 
                            startDate={startDate} 
                            endDate={endDate}
                        />
                    </div>
                    <div className='spacing-8'/>
                    <div className={dashboardStyle.DBLineGraphS__Body}>
                        <LGSChart3 
                            isLoading={isLoading} 
                            noData={noData[0]} 
                            data={dashboardData?.lineS} 
                            dbLineGraphSIdx={2} 
                            startDate={startDate} 
                            endDate={endDate}
                        />
                    </div>
                </div>
                <div className={dashboardStyle.DBLineGraphS__Wrap}>
                    <div className={dashboardStyle.DBLineGraphS__Body}>
                        <LGSChart4 
                            isLoading={isLoading} 
                            noData={noData[0]} 
                            data={dashboardData?.lineS} 
                            dbLineGraphSIdx={3} 
                            startDate={startDate} 
                            endDate={endDate}
                        />
                    </div>
                    <div className='spacing-8'/>
                    <div className={dashboardStyle.DBLineGraphS__Body}>
                        <LGSChart5 
                            isLoading={isLoading} 
                            noData={noData[0]} 
                            data={dashboardData?.lineS} 
                            dbLineGraphSIdx={4} 
                            startDate={startDate} 
                            endDate={endDate}
                        />
                    </div>
                </div>
            </div>
            <div className='spacing-24'/>
            {/* db line graph large block */}
            <div className={dashboardStyle.DBGraphL__Container}>
                <div className={dashboardStyle.DBGraphL__Header}>
                    <p className='h4 fc-gray-800'>구매, 장바구니 건수</p>
                </div>
                <div className={dashboardStyle.DBGraphL__TextContainer}>
                    {
                        dbLineGraphLTextContents.map((title, key) => (
                            <div key={key} className={dashboardStyle.DBGraphL__TextWrap}>
                                <p className='h6 fc-gray-600 t-center-w100'>{title}</p>
                                <div className='spacing-16'/>
                                {
                                    isLoading ? (
                                        <img src='/img/units/loading-ani-S.gif' width={105} height={30}/>
                                    ) : (
                                        <p className='h3 fc-gray-800 t-center-w100'>
                                            {
                                                noData[1] ? `No data` :
                                                    (dashboardData?.lineL[0].textDatas[key].unitPosition === 'prefix' 
                                                    ? `${dashboardData?.lineL[0].textDatas[key].unit}${getFormattedNumberValue(dashboardData?.lineL[0].textDatas[key].value)}` 
                                                    : `${getFormattedNumberValue(dashboardData?.lineL[0].textDatas[key].value)}${dashboardData?.lineL[0].textDatas[key].unit}` )
                                            }
                                        </p>
                                    )
                                }
                            </div>
                        ))
                    }
                </div>
                <div className={dashboardStyle.DBGraphL__Body__line}>
                    <CartNPurchaseChart 
                        isLoading={isLoading} 
                        noData={noData[1]} 
                        data={dashboardData?.lineL} 
                        startDate={startDate} 
                        endDate={endDate}
                    />
                </div>
                <div className={dashboardStyle.DBGraphL__Footer}>
                    <div className={dashboardStyle.DBGraphL__Legend}>
                        <div className={dashboardStyle.DBGraphL__Marker__cart}/>
                        <div className='spacing-8'/>
                        <p className='h7 fc-gray-700'>총 장바구니 담기 건수</p>
                    </div>
                    <div className='spacing-8'/>
                    <div className={dashboardStyle.DBGraphL__Legend}>
                        <div className={dashboardStyle.DBGraphL__Marker__purchase}/>
                        <div className='spacing-8'/>
                        <p className='h7 fc-gray-700'>총 구매건수</p>
                    </div>
                </div>
            </div>
            <div className='spacing-24'/>
            {/* 퍼널 chart */}
            <div className={dashboardStyle.DBGraphL__Container}>
                <div className={dashboardStyle.DBGraphL__Header}>
                    <p className='h4 fc-gray-800'>상품 탐색 퍼널</p>
                </div>
                <div className={dashboardStyle.DBGraphL__TextContainer}>
                    {
                        dbBarGraphLTextContents.map((title, key) => (
                            <div key={key} className={dashboardStyle.DBGraphL__TextWrap}>
                                <p className='h6 fc-gray-600 t-center-w100'>{title}</p>
                                <div className='spacing-16'/>
                                {
                                    isLoading ? (
                                        <img src='/img/units/loading-ani-S.gif' width={105} height={30}/>
                                    ) : (
                                        <p className='h3 fc-gray-800 t-center-w100'>
                                            {
                                                noData[2] ? `No data` :
                                                    (dashboardData?.barL[0].textDatas[key].unitPosition === 'prefix' 
                                                    ? `${dashboardData?.barL[0].textDatas[key].unit}${getFormattedDecimalValue(dashboardData?.barL[0].textDatas[key].value, dashboardData?.barL[0].textDatas[key].decimalPlaces)}` 
                                                    : `${getFormattedDecimalValue(dashboardData?.barL[0].textDatas[key].value, dashboardData?.barL[0].textDatas[key].decimalPlaces)}${dashboardData?.barL[0].textDatas[key].unit}` )
                                            }
                                        </p>
                                    )
                                }
                            </div>
                        ))
                    }
                </div>
                <div className={dashboardStyle.DBGraphL__Body__bar}>
                    <FunnelChart 
                        isLoading={isLoading} 
                        noData={noData[2]} 
                        data={dashboardData?.barL}
                        startDate={startDate} 
                        endDate={endDate}
                    />
                </div>
            </div>
            <ConsoleFooterSpacer />
            <ConsoleFooter />
        </div>
    )
};

const fillMultiSeriesMissingDates = (multiSeries) => {
    if (!multiSeries) return [];

    const allDates = new Set();
    multiSeries.forEach(series => {
        series.data.forEach(entry => allDates.add(entry.x));
    });

    const sortedDates = Array.from(allDates).sort((a, b) => new Date(a) - new Date(b));

    const filledSeries = multiSeries.map(series => {
        const dataMap = new Map(series.data.map(entry => [entry.x, entry.y]));
        const filledData = sortedDates.map(x => ({
            x,
            y: dataMap.has(x) ? dataMap.get(x) : null
        }));
        return { ...series, data: filledData };
    });

    return filledSeries;
}

export default Dashboard