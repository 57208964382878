export const graphColors = {
    primary_800: '#154CCA',
    purple_800: '#6225C6',
    skyblue_900: '#49ADE5',
    primary_900: '#103B9C',
    tealgreen_700: '#1C89A1',
    primary_700: '#0025E4',
    primary_200: '#BBC8E5',
    gray_200: '#EFEFEF',
    gray_300: '#E1E1E1',
    gray_500: '#999999',
    gray_600: '#666666',
    gray_700: '#444444',
    gray_800: '#222222',
}

export const createDBLineSmallOptions = ({ colors, tooltip }) => ({
    ...DBLineGraphSOption,
    colors,
    tooltip,
})

export const DBLineGraphSOption = {
    chart: {
        type: 'area',
        height: 100,
        width: '100%',
        sparkline: {
          enabled: true
        },
        zoom: {
            enabled: false,
        },
        toolbar: {
            show: false
        },
        parentHeightOffset: 0,
        redrawOnWindowResize: true,
        redrawOnParentResize: true,
        animations: {
            enabled: true,
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
        },
        events: {
            mounted: (chart) => {
                chart.windowResizeHandler();
            }
        }
    },
    dataLabels: {
        enabled: false,
    },
    fill: {
        type: 'gradient',
        gradient: {
            type: 'vertical',
            shadeIntensity: 1,
            opacityFrom: 0.5,
            opacityTo: 0.5,
            inverseColor: false,
            stops: [0, 100]
        }
    },
    xaxis: {
        tickAmount: 0,
        labels: {
            show: false
        },
        axisBorder: {
            show: false,
            color: graphColors.gray_300,
            height: 2,
            width: '100%',
            offsetY: 0
        },
        axisTicks: {
            show: false
        },
        crosshairs: {
            show: true,
            width: 1,
            stroke: {
                color: graphColors.gray_500,
                width: 1,
                dashArray: 0,
            }
        },
        tooltip: {
            enabled: false
        }
    },
    yaxis: {
        show: false,
        min: function (min) { return min },
        max: function (max) { return max },
    },
    stroke: {
        width: 2,
        curve: 'straight'
    },
    grid: {
        show: false,
        padding: {
            top: 8,
            right: 0,
            bottom: 0,
            left: 0
        },
    },
}

export const DBLineGraphLOption = {
    chart: {
        type: 'line',
        height: 320,
        toolbar: {
            show: false
        },
        zoom: {
            enabled: false
        }
    },
    colors: [graphColors.primary_200, graphColors.primary_800],
    grid: {
        show: true,
        borderColor: graphColors.gray_300,
        strokeDashArray: 5,
        padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 16,
        }
    },
    xaxis: {
        tickAmount: 0,
        labels: {
            show: false
        },
        axisBorder: {
            show: true,
            color: graphColors.gray_800,
            height: 1,
            width: '100%',
            offsetY: 0,
        },
        axisTicks: {
            show: false
        },
        tooltip: {
            enabled: false,
        },
        crosshairs: {
            show: true,
            width: 1,
            stroke: {
                color: graphColors.gray_500,
                width: 1,
                dashArray: 0,
            }
        }
    },
    yaxis: {
        min: 0,
        show: true,
        tickAmount: 3,
        forceNiceScale: true,
        labels: {
            style: {
                colors: [graphColors.gray_700],
                fontSize: '14px',
                fontFamily: 'Pretentard, Arial, sans-serif',
                fontWeight: 400,
            }
        },
    },
    stroke: {
        width: 2,
        curve: 'straight'
    },
    legend: {
        show: false,
        horizontalAlign: 'left',
    }
}

export const DBBarGraphLOption = {
    chart: {
        type: 'bar',
        height: 480,
        toolbar: {
            show: false
        },
        zoom: {
            enabled: false
        },
        events: {
            updated: function(chartContext, config) {
                const svg = chartContext.el.querySelector('svg');
                const gridBorders = svg.querySelector('.apexcharts-grid-borders');
                const barSeries = svg.querySelector('.apexcharts-bar-series.apexcharts-plot-series');
                
                if (gridBorders && barSeries) {
                    gridBorders.parentNode.insertBefore(gridBorders, barSeries.nextSibling);
                }
            }
        }
    },
    colors: [graphColors.gray_200],
    grid: {
        show: true,
        borderColor: graphColors.gray_300,
        strokeDashArray: 5,
        padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 16,
        },
        position: 'back',
    },
    plotOptions: {
        bar: {
            borderRadius: 4,
            dataLabels: {
                position: 'top', // top, center, bottom
            },
            borderRadiusApplication: 'end',
            columnWidth: '60%',
        }
    },
    dataLabels: {
        enabled: true,
        formatter: function (val) {
            if (val >= 1_000_000) {
                return `${(val / 1_000_000).toFixed(2)}M`;
            } else if (val >= 1_000) {
                return `${(val / 1_000).toFixed(2)}k`;
            } else {
                return `${val}`;
            }
        },
        offsetY: -22,
        style: {
            fontSize: '12px',
            fontWeight: 600,
            fontFamily: 'Pretendard, Arial, sans-serif',
            colors: [graphColors.gray_500]
        }
    },
    xaxis: {
        // categories: ["젠투 노출", "채팅창 유입", "대화 세션", "상품 클릭", "장바구니 담기 또는 구매"],
        position: 'bottom',
        type: 'category',
        axisBorder: {
            show: true,
            color: graphColors.gray_800,
            height: 1,
            width: '100%',
            offsetY: 0,
            zIndex: 999,
        },
        axisTicks: {
            show: false
        },
        crosshairs: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
        labels: {
            style: {
                colors: [graphColors.gray_700],
                fontSize: '14px',
                fontFamily: 'Pretentard, Arial, sans-serif',
                fontWeight: 400,
            }
        }
    },
    yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
            show: true,
            formatter: function (val) {
                if (val >= 1_000_000) {
                    return `${(val / 1_000_000).toFixed(0)}M`;
                } else if (val >= 1_000) {
                    return `${(val / 1_000).toFixed(0)}k`;
                } else {
                    return `${val}`;
                }
            },
            style: {
                colors: [graphColors.gray_700],
                fontSize: '14px',
                fontFamily: 'Pretentard, Arial, sans-serif',
                fontWeight: 400,
            },
        },
        logarithmic: true,
        logBase: 10,
        min: function(min) { return min },
        max: function(max) { return max },
        forceNiceScale: true,
    },
    states: {
        hover: {
            filter: {
                type: 'none'
            },
        },
        active: {
            filter: {
                type: 'none'
            }
        }
    },
}

export const formatStartEndDate = (dateStr) => {
    const date = new Date(dateStr);
    const formattedDate = date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    });

    return formattedDate;
}

export const formatValue = (w, seriesIndex, dataPointIndex) => {
    const rawValue = w.config.series[seriesIndex].data[dataPointIndex].y; 
    if (rawValue === null) return null;
    const formattedValue = new Intl.NumberFormat('en-US').format(rawValue);
    return formattedValue;
}


export const DBLineGraphS_CustomTooltip = (w, seriesIndex, formattedX, formattedY, color, unit, unitPosition) => {
    const formattedYwithUnit = unitPosition === 'prefix' 
        ? `${unit}${formattedY}` 
        : `${formattedY}${unit}`;
    
    return (
        '<div class="custom-tooltip" style="display: flex; flex-direction: column; width: fit-content; padding: 8px 12px; background: #FFF; gap: 8px; ">' +
            '<p style="font-size: 12px; color: #666666; font-weight: 400;">' + formattedX + '</p>' +
            '<div class="custom-tooltip-body" style="display: flex; align-items: center; gap: 12px; align-self: stretch;">' +
                '<div class="custom-tooltip-label" style="display: flex; align-items: center; gap: 4px; width: fit-content; justify-content: flex-start;">' + 
                    '<div style="width: 14px; height: 14px; border-radius: 4px; background:' + color + ';"></div>' +
                    '<p style="font-size: 12px; color: #666666; font-weight: 600">' + w.config.series[seriesIndex].name + '</p>' +
                '</div>' +
                '<p style="font-size: 12px; color: #222222; font-weight: 600">' + formattedYwithUnit + '</p>' +
            '</div>' +
        '</div>'
    );
}

export const DBLineGraphL_CustomTooltip = (w, seriesIndex, formattedX, formattedYs, colors) => {
    return (
        '<div class="custom-tooltip" style="display: flex; flex-direction: column; width: fit-content; padding: 8px 12px; background: #FFF; gap: 8px; ">' +
            '<p style="font-size: 12px; color: #666666; font-weight: 400;">' + formattedX + '</p>' +
            '<div class="custom-tooltip-body" style="display: flex; align-items: center; gap: 12px; align-self: stretch;">' +
                '<div class="custom-tooltip-label" style="display: flex; align-items: center; gap: 4px; width: fit-content; justify-content: flex-start;">' + 
                    '<div style="width: 14px; height: 14px; border-radius: 4px; background:' + colors[0] + ';"></div>' +
                    '<p style="font-size: 12px; color: #666666; font-weight: 600">총 장바구니 담기 건수</p>' +
                '</div>' +
                '<p style="font-size: 12px; color: #222222; font-weight: 600">' + formattedYs[0] + '</p>' +
            '</div>' +
            '<div class="custom-tooltip-body" style="display: flex; align-items: center; gap: 12px; align-self: stretch;">' +
                '<div class="custom-tooltip-label" style="display: flex; align-items: center; gap: 4px; width: fit-content; justify-content: flex-start;">' + 
                    '<div style="width: 14px; height: 14px; border-radius: 4px; background: ' + colors[1] + ';"></div>' +
                    '<p style="font-size: 12px; color: #666666; font-weight: 600">총 구매건수</p>' +
                '</div>' +
                '<p style="font-size: 12px; color: #222222; font-weight: 600">' + formattedYs[1] + '</p>' +
            '</div>' +
        '</div>'
    );
}

export const DBBarGraphL_CustomTooltip = (w, seriesIndex, dataPointIndex, formattedY, color) => {
    return (
        '<div class="custom-tooltip" style="display: flex; flex-direction: column; width: fit-content; padding: 8px 12px; background: #FFF; gap: 8px; ">' +
            '<div class="custom-tooltip-body" style="display: flex; align-items: center; gap: 12px; align-self: stretch;">' +
                '<div class="custom-tooltip-label" style="display: flex; align-items: center; gap: 4px; width: fit-content; justify-content: flex-start;">' + 
                    '<div style="width: 14px; height: 14px; border-radius: 4px; background: ' + color + ';"></div>' +
                    '<p style="font-size: 12px; color: #666666; font-weight: 600">' + w.globals.labels[dataPointIndex] + '</p>' +
                '</div>' +
                '<p style="font-size: 12px; color: #222222; font-weight: 600">' + formattedY + '</p>' +
            '</div>' +
        '</div>'
    );
}