import React, { useEffect, useState } from 'react';
import agentStyle from '../../style/agent.module.css';
import unitsStyle from '../../style/units.module.css';
import { updateExperimentInfo } from '../../apis/services/create/api';
import expStyle from '../../style/experiment.module.css';
import { DefaultButton } from '../Units/Button';
import { useNavigate } from 'react-router-dom';

export const BetaFeatures = (props) => {
    const {compProps} = props;
    const navigate = useNavigate();
    const setIsContentOpen = compProps.setIsContentOpen;
    const userAccessLevel = compProps.userAccessLevel;
    const initExpSettings = compProps.initExpSettings;
    const updateExpSetting = compProps.updateExpSetting;
    const setIsToastVisible = compProps.setIsToastVisible;
    const setUnsavedChanges = compProps.setUnsavedChanges;
    const partnerId = sessionStorage.getItem('ptid');
    const chatbotId = sessionStorage.getItem('cbid');
    const [tempExpSettings, setTempExpSettings] = useState(initExpSettings);    // 저장 버튼을 누르면 update

    const handleSwitchClick = (key) => {
        const newExpSettings = { ...tempExpSettings, [key]: { ...tempExpSettings[key], activated: !tempExpSettings[key].activated } };
        setTempExpSettings(newExpSettings);
        setUnsavedChanges(true);
    }

    const handleSaveButtonClick = () => {
        console.log('tempExpSettings @handleSaveButtonClick', tempExpSettings);
        updateExperimentInfo(partnerId, chatbotId, tempExpSettings)
            .then(res => {
                updateExpSetting('detailedResponse', tempExpSettings.detailedResponse);
                updateExpSetting('customPrompt', tempExpSettings.customPrompt);
                updateExpSetting('warningMessage', tempExpSettings.warningMessage);
                setIsToastVisible(true);
                setUnsavedChanges(false);
                sessionStorage.setItem('cpt', tempExpSettings.customPrompt.activated);
            })
    }

    useEffect(() => {
        setTempExpSettings(initExpSettings);
        console.log(initExpSettings);
        if (initExpSettings) {
            sessionStorage.setItem('cpt', initExpSettings.customPrompt.activated);
        }
    }, [initExpSettings]);

    return (
        <>
            <div className={expStyle.BetaFeatures__Container}>
                <div className={expStyle.BetaFeatures__Wrap}>
                    <div className={expStyle.BetaFeatures__TextWrap}>
                        <p className='h6 fc-gray-600'>{tempExpSettings?.detailedResponse?.title}</p>
                        <div className='spacing-12'/>
                        <p className='h10 fc-gray-500'>{tempExpSettings?.detailedResponse?.description}</p>
                    </div>
                    <img 
                        src={tempExpSettings?.detailedResponse?.activated ? '/img/units/switch-active-on.png' : '/img/units/switch-active-off.png'} 
                        className={expStyle.BetaFeatures__SwitchImg__active}
                        onClick={() => {handleSwitchClick('detailedResponse')}}
                        alt=''
                    />
                </div>
                <div className={unitsStyle.Divider} />
                {/* 커스텀 프롬프트*/}
                <div className={expStyle.BetaFeatures__Wrap}>
                    <div className={expStyle.BetaFeatures__TextWrap}>
                        <p className='h6 fc-gray-600'>{tempExpSettings?.customPrompt?.title}</p>
                        <div className='spacing-12'/>
                        <p className='h10 fc-gray-500'>{tempExpSettings?.customPrompt?.description}</p>
                        {
                            tempExpSettings?.customPrompt?.activated &&
                            <>
                                <div className='spacing-12'/>
                                <div 
                                    style={{display: 'flex', cursor: 'pointer'}}
                                    onClick={() => {navigate('/home')}}
                                >
                                    <p className='h6 fc-prim-800'>커스텀 프롬프트 설정</p>
                                    <div className='spacing-4'/>
                                    <img src='/img/units/export-icon-p800.png' width={20} height={20}/>
                                </div>
                            </>
                        }
                    </div>
                    <img 
                        src={tempExpSettings?.customPrompt?.activated ? '/img/units/switch-active-on.png' : '/img/units/switch-active-off.png'} 
                        className={expStyle.BetaFeatures__SwitchImg__active}
                        onClick={() => {handleSwitchClick('customPrompt')}}
                        alt=''
                    />
                </div>
                <div className={unitsStyle.Divider} />
                <div className={expStyle.BetaFeatures__Wrap}>
                    <div className={expStyle.BetaFeatures__TextWrap}>
                        <p className='h6 fc-gray-600'>{tempExpSettings?.warningMessage?.title}</p>
                        <div className='spacing-12'/>
                        <p className='h10 fc-gray-500'>{tempExpSettings?.warningMessage?.description}</p>
                    </div>
                    <img 
                        src={tempExpSettings?.warningMessage?.activated ? '/img/units/switch-active-on.png' : '/img/units/switch-active-off.png'} 
                        className={expStyle.BetaFeatures__SwitchImg__active}
                        onClick={() => {handleSwitchClick('warningMessage')}}
                        alt=''
                    />
                </div>
            </div>
            <div className={agentStyle.AgentCustom__ButtonWrap__betaFeatures}>
                <button 
                    className={agentStyle.AgentCustom__Button} 
                    onClick={() => {
                        handleSaveButtonClick();
                        setIsContentOpen(false);
                    }}
                >
                    <p className='h4 fc-white'>저장</p>
                </button>
            </div>
        </>
    );
};