
import { useEffect, useState } from 'react';
import { SquaredAccodianList } from './Accodian';
import { deleteCustomPrompt, deployCustomPrompt, fetchCustomPrompt, updateCustomPrompt } from '../../apis/api/experiments/api';
import { accodianUIConfig, limitPromptAmount } from '../../data/promptData';
import customPromptStyle from '../../style/customPrompt.module.scss';
import { ConfirmModal } from './Modal';

const CustomPrompt = (props) => {
  const [isDockOpen, setIsDockOpen] = useState(true);
  const [enabledDeploy, setEnabledDeploy] = useState(false);
  const [mode, setMode] = useState({
    basicSettingData: 'list',
    productSettingData: 'list',
    customerServiceSettingData: 'list',
    isEditing: false,
  });
  const [showTooltip, setShowTooltip] = useState(false);
  const [isDeployModalVisible, setIsDeployModalVisible] = useState(false);
  const [promptDataUpdateState, setPromptDataUpdateState] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteListId, setDeleteListId] = useState(null);
  const [deleteListType, setDeleteListType] = useState(null);
  const clickedListInfo = JSON.parse(sessionStorage.getItem('clickedListInfo')) || {
    configType: '',
    configList: [],
    listIndex: 0,
  };
  const promptDataDeployed = JSON.parse(sessionStorage.getItem('prompt-data-deployed'));
  const promptAmount = {
    basicSettingData: {
      current: Number(promptDataDeployed?.basicSettingData[0]?.condition.length > 0) + Number(promptDataDeployed?.basicSettingData[1]?.condition.length > 0),
      limit: limitPromptAmount.basicSettingData,
    },
    productSettingData: {
      current: promptDataDeployed?.productSettingData?.length || 0,
      limit: limitPromptAmount.productSettingData,
    },
    customerServiceSettingData: {
      current: promptDataDeployed?.customerServiceSettingData?.length || 0,
      limit: limitPromptAmount.customerServiceSettingData,
    },
  }
  promptAmount.total = {
    current: promptAmount.basicSettingData.current + promptAmount.productSettingData.current + promptAmount.customerServiceSettingData.current,
    limit: limitPromptAmount.total,
  };

  useEffect(() => {
    fetchCustomPrompt().then(data => {
      if (data.basicSettingData?.activated) {
        setEnabledDeploy(true);
      } else {
        setEnabledDeploy(false);
      }
      setPromptDataUpdateState(false);
    });
  }, [isDockOpen, promptDataUpdateState]);

  const handleDeleteAccodian = (configType, listType, id) => {
    if (configType === 'basicSettingData') {
      const promptDataDeployed = JSON.parse(sessionStorage.getItem('prompt-data-deployed'));
      const payload = listType === 'company' ? promptDataDeployed.basicSettingData[0] : promptDataDeployed.basicSettingData[1];
      payload.id = id;
      payload.activated = Boolean(false);
      payload.condition = '';

      updateCustomPrompt(configType, payload);
      setTimeout(() => {
        setPromptDataUpdateState(true);
      }, 100);
    } else {
      deleteCustomPrompt(id)
        .then(res => {
          setTimeout(() => {
            setPromptDataUpdateState(true);
            sessionStorage.setItem('clickedListInfo', JSON.stringify({
              configType: '',
              configList: [],
              listIndex: 0,
            }));
          }, 100);
        })
        .catch(err => {
          console.log('err', err);
        })
    }
  }

  return (
    <div style={{ width: isDockOpen ? '100%' : '0%', height: '100%', flexGrow: 1, flexShrink: 1 }}>
      {
        isDeployModalVisible &&
        <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1000, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <ConfirmModal
            title={'변경사항을 쇼핑몰에 배포하려면 SDK 설치가 필요합니다. 설치를 완료하셨나요?'}
            subText={'변경사항을 적용하지 않으면 작업 내용이 저장되지 않습니다. SDK 설치가 필요하다면 가이드를 참고해 주세요.'}
            submitFunc={() => {
              deployCustomPrompt().then(data => {
                console.log("deployCustomPrompt data", data);
              });
              setIsDeployModalVisible(false);
            }}
            cancelFunc={() => window.open('https://waddlelab.notion.site/web-SDK-16d1f2d71bda808fb428f4a5c35159f1', "_blank")}
            setIsModalVisible={setIsDeployModalVisible}
            confirmText={'네, 배포하기'}
            cancelText={'아니요, 설치 가이드로 이동'}
          />
        </div>
      }
      {
        isDeleteModalVisible && deleteListId &&
        <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1000, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <ConfirmModal
            title={'정말 삭제하시겠습니까?'}
            subText={'프롬프트를 삭제하면 되돌릴 수 없습니다'}
            submitFunc={() => {
              handleDeleteAccodian(clickedListInfo.configType, deleteListType, deleteListId);
              setDeleteListId(null);
              setDeleteListType(null);
            }}
            cancelFunc={() => setIsDeleteModalVisible(false)}
            setIsModalVisible={setIsDeleteModalVisible}
            confirmText={'삭제하기'}
            cancelText={'취소하기'}
            buttonColor={'#EC3E48'}
          />
        </div>
      }
      <div
        className={customPromptStyle.CustomPrompt__Container}
        onMouseOver={(e) => {
          e.target.style.backgroundColor = '#efefef';
        }}
        onMouseOut={(e) => {
          e.target.style.backgroundColor = 'transparent';
        }}
        onClick={() => {
          setIsDockOpen(!isDockOpen);
        }}
      >
        <img src={isDockOpen ? '/img/chatPreview/dock-open.png' : '/img/chatPreview/dock-close.png'} width={24} height={24} />
      </div>
      <div
        className={customPromptStyle.CustomPrompt__DockContainer}
        style={{ transform: isDockOpen ? 'translateX(0%)' : 'translateX(100%)', transition: 'all 0.3s ease-in-out' }}
      >
        <div style={{ width: '100%', height: 'fit-content', display: 'flex', justifyContent: 'space-between', padding: '12px 32px' }}>
          <div>
            <div style={{ width: '100%', height: 'fit-content', display: 'flex', alignItems: 'center' }}>
              <p className='h6 fc-gray-700'>커스텀 프롬프트 설정하기</p>
              <div className='spacing-4' />
              <div style={{ position: 'relative' }}>
                <img
                  src='/img/units/tooltip-icon-g300.png' width={24} height={24}
                  onMouseOver={() => { setShowTooltip(true) }}
                  onMouseLeave={() => { setShowTooltip(false) }}
                />
                {
                  showTooltip &&
                  <div style={{ position: 'absolute', top: '100%', left: '50%', transform: 'translateX(-50%)', width: 'fit-content', backgroundColor: '#222', padding: '14px', borderRadius: '10px', boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.25)', zIndex: 5 }}>
                    <p
                      className='h10 fc-gray-400'
                      style={{ width: '208px', wordBreak: 'keep-all', whiteSpace: 'pre-wrap' }}
                    >적용하기 버튼을 누르면 즉시 커스텀 프롬프트가 실제 프로덕트에 반영됩니다</p>
                  </div>
                }
              </div>
            </div>
            <p className='h12 fc-gray-500'>{promptAmount.total.current}/{limitPromptAmount.total}</p>
          </div>
          {/* 배포하기 플로우 보류 */}
          {/* <div>
            <button
              className={enabledDeploy ? customPromptStyle.CustomPrompt__Button__deploy : customPromptStyle.CustomPrompt__Button__deploy__disabled}
              onClick={() => {
                if (enabledDeploy) {
                  setIsModalVisible(true);
                }
              }}
            >
              <p className={enabledDeploy ? 'h8 fc-white' : 'h8 fc-gray-500'}>배포하기 ▶︎</p>
            </button>
          </div> */}
        </div>
        <div
          style={{ width: '100%', height: 'fit-content', padding: '0px 32px' }}
        >
          <SquaredAccodianList
            accodianUIConfig={accodianUIConfig}
            mode={mode}
            setMode={setMode}
            setPromptDataUpdateState={setPromptDataUpdateState}
            promptAmount={promptAmount}
            setIsDeleteModalVisible={setIsDeleteModalVisible}
            setDeleteListId={setDeleteListId}
            setDeleteListType={setDeleteListType}
          />
        </div>
      </div>
    </div>
  )
}

export default CustomPrompt;