import designModeStyle from '../../style/designMode.module.css';
import { useEffect, useState } from "react";
import { fetchChatbotInfo, updateChatbotInfo } from "../../apis/services/create/api";
import { TopAccodian } from "../Units/Accodian"
import { AgentCustom } from "./AgentCustom.jsx";
import { WidgetSet } from "./WidgetSet";
import { chatBotInitSettings } from "../../data/unitsData";
import Toast from "../Units/Toast";
import { ConfirmModal } from "../Units/Modal";
import { postScript } from "../../apis/api/auth/api";
import { unstable_useBlocker as useBlocker } from 'react-router-dom';
import { usePreventClose } from '../../hooks/usePreventClose.js';
import { ConsoleFooterSpacer, ConsoleFooter } from '../Units/ConsoleFooter.jsx';

const DesignMode = () => {
    const partnerId = sessionStorage.getItem('ptid');
    const chatbotId = sessionStorage.getItem('cbid');
    const [initSettings, setInitSettings] = useState();
    //const [isContentOpen, setIsContentOpen] = useState([true, false]);
    const [isAgentCustomOpen, setIsAgentCustomOpen] = useState(true);
    const [isWidgetSetOpen, setIsWidgetSetOpen] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [clickedButton, setClickedButton] = useState([false, false]);
    const [unsavedChanges, setUnsavedChanges] = useState(false);

    // 창 닫기 (document navigation) 감지 blocker
    usePreventClose({ unsavedChanges });

    // 라우팅 네비게이션 blocker
    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) => 
            unsavedChanges &&
            currentLocation.pathname !== nextLocation.pathname
    )

    const handleConfirm = () => {
        blocker.proceed();
        setUnsavedChanges(false);
    }

    const updateSetting = (key, newValue) => {
        setInitSettings((prev) => {
            if (!prev || !prev.hasOwnProperty(key)) return { ...prev, [key]: newValue };

            let isChanged;
            if (Array.isArray(prev[key]) && Array.isArray(newValue)) {
                isChanged = JSON.stringify(prev[key]) !== JSON.stringify(newValue);
            } else if (typeof prev[key] === 'object' && prev[key] !== null) {
                isChanged = JSON.stringify(prev[key]) !== JSON.stringify(newValue);
            } else {
                isChanged = prev[key] !== newValue;
            }

            if (isChanged) {
                setUnsavedChanges(true);
            }
            return {
                ...prev,
                [key]: newValue,
            };
        });
    }

    const sendQuery = () => {
        postScript(partnerId, chatbotId);
        updateChatbotInfo(partnerId, chatbotId, initSettings)
            .then(() => {
                setIsModalVisible(false);
                setIsToastVisible(true);
                setUnsavedChanges(false);
            })
        sessionStorage.setItem('set', initSettings);
    }

    useEffect(() => {
        fetchChatbotInfo(partnerId, chatbotId)
            .then(res => {
                if (!res) {
                    res = chatBotInitSettings;
                    updateSetting('profileImg', res.profileImg);
                    updateSetting('name', res.name);
                    updateSetting('colorCode', res.colorCode);
                    updateSetting('greetingMessage', res.greetingMessage);
                    updateSetting('carouselType', res.carouselType);
                    updateSetting('exceptKeyword', res.exceptKeyword);
                    updateSetting('examples', res.examples);
                    updateSetting('position', res.position);
                    updateSetting('mobilePosition', res.mobilePosition);
                } else {
                    updateSetting('profileImg', res.profileImg);
                    updateSetting('name', res.name);
                    updateSetting('colorCode', res.colorCode);
                    updateSetting('greetingMessage', res.greetingMessage);
                    updateSetting('carouselType', res.carouselType);
                    updateSetting('exceptKeyword', res.exceptKeyword);
                    updateSetting('examples', res.examples);
                    updateSetting('position', res.position);
                    updateSetting('mobilePosition', res.mobilePosition);
                }
            })
    }, [])

    const chatProps = {
        initSettings: initSettings,
        updateSetting: updateSetting,
        isContentOpen: isAgentCustomOpen,
        setIsContentOpen: setIsAgentCustomOpen,
        setIsWidgetSetOpen: setIsWidgetSetOpen,
        setClickedButton: setClickedButton,
        setUnsavedChanges: setUnsavedChanges,
    }

    const widgetProps = {
        initSettings: initSettings,
        updateSetting: updateSetting,
        isContentOpen: isWidgetSetOpen,
        setIsContentOpen: setIsWidgetSetOpen,
        setIsToastVisible: setIsToastVisible,
        setIsModalVisible: setIsModalVisible,
        clickedButton: clickedButton,
        setClickedButton: setClickedButton,
        setUnsavedChanges: setUnsavedChanges,
    }

    return (
        <div className={designModeStyle.DesignMode__Wrap}>
            {
                blocker.state === "blocked" &&
                <div className={designModeStyle.Modal__Background}>
                    <div className={designModeStyle.Modal__Wrap}>
                        <ConfirmModal 
                            title={'페이지를 나가시겠습니까?'} 
                            subText={'변경사항이 저장되지 않을 수 있습니다'} 
                            submitFunc={handleConfirm} 
                            cancelFunc={() => blocker.reset()}
                            setIsModalVisible={() => {}}
                            confirmText={'나가기'}
                            cancelText={'취소'}
                        />
                    </div>
                </div>
            }
            {
                isModalVisible &&
                <div className={designModeStyle.Modal__Background}>
                    <div className={designModeStyle.Modal__Wrap}>
                        <ConfirmModal 
                            title={'변경사항을 쇼핑몰에 배포하려면 SDK 설치가 필요합니다. 설치를 완료하셨나요?'} 
                            subText={'변경사항을 적용하지 않으면 작업 내용이 저장되지 않습니다. SDK 설치가 필요하다면 가이드를 참고해 주세요.'} 
                            submitFunc={sendQuery} 
                            cancelFunc={() => window.open('https://waddlelab.notion.site/web-SDK-16d1f2d71bda808fb428f4a5c35159f1', "_blank")}
                            setIsModalVisible={setIsModalVisible}
                            confirmText={'네, 배포하기'}
                            cancelText={'아니요, 설치 가이드로 이동'}
                        />
                    </div>
                </div>
            }
            <div className={designModeStyle.DesignMode__Container}>
                {
                    isToastVisible &&
                    <div className={designModeStyle.Toast__Container}>
                        <div className={designModeStyle.Toast__Block}>
                            <Toast status={'success'} message={'변경 사항이 반영되었습니다'} isLinkable={false} isVisible={isToastVisible} setIsVisible={setIsToastVisible} />
                        </div>
                    </div>
                }
                <div className={designModeStyle.DesignMode__InterfaceSetting}>
                    <TopAccodian 
                        title={"채팅창 설정"}
                        badgeText={"Step 1"}
                        ContentComp={AgentCustom}
                        compProps={chatProps}
                    />
                    <div className="spacing-32" />
                    <TopAccodian
                        title={"위젯 설정"}
                        badgeText={"Step 2"}
                        ContentComp={WidgetSet}
                        subText={"쇼핑몰에서 사용하실 위젯의 종류를 모두 선택해 주세요"}
                        compProps={widgetProps}
                    />
                </div>
            </div>
            <ConsoleFooterSpacer />
            <ConsoleFooter />
        </div>
    )
}

export default DesignMode;