import React, { useEffect, useState } from 'react';
import unitsStyle from '../../style/units.module.css';
import { deleteCustomPrompt, updateCustomPrompt } from '../../apis/api/experiments/api';
import { customPromptDataInitial, LIMIT_PROMPT_AMOUNT } from '../../data/promptData';
import { ConfirmModal, InfoModal } from './Modal';

export const TopAccodian = (props) => {
    const { title, isBadgeText = true, badgeText = '', badgeIcon = '', ContentComp, subText, compProps } = props;
    const arrowRightUrl = "/img/units/accodian_arrow_right.png";
    const arrowDownUrl = "/img/units/accodian_arrow_down.png";
    const isContentOpen = compProps.isContentOpen;
    const setIsContentOpen = compProps.setIsContentOpen;

    const openContents = () => {
        setIsContentOpen(prev => !prev);
    }

    return (
        <div
            className={unitsStyle.Accodian__Container}
        >
            <div
                className={`${unitsStyle.Accodian__TopWrapper} pointer`}
                onClick={() => { openContents() }}
            >
                <div className={unitsStyle.Accodian__TopLeft}>
                    <div className={unitsStyle.Accodian__BadgeWrapper}>
                        {
                            isBadgeText ?
                                <div className={unitsStyle.Accodian__TopBadge__primary}>
                                    <p className="h8 fc-gray-600">{badgeText}</p>
                                </div>
                                :
                                <img src={badgeIcon} width={33} height={33} />
                        }
                        <div className="spacing-12" />
                        <p className="h4 fc-gray-700">{title}</p>
                    </div>
                    {
                        subText &&
                        <div>
                            <div className="spacing-12" />
                            <p className='h7 fc-gray-600'>{subText}</p>
                        </div>
                    }
                </div>
                <img
                    src={isContentOpen ? arrowDownUrl : arrowRightUrl} width={24} height={24}
                    className='pointer'
                />
            </div>
            {
                isContentOpen &&
                <div className={unitsStyle.Accodian__BottomWrapper}>
                    <ContentComp compProps={compProps} />
                </div>
            }
        </div>
    )
}

export const SquaredAccodianList = (props) => {
    const { accodianUIConfig, mode, setMode, setPromptDataUpdateState, promptAmount, setIsDeleteModalVisible, setDeleteListId, setDeleteListType } = props;
    const arrowUpUrl = "/img/units/chevron-up.png";
    const arrowDownUrl = "/img/units/chevron-down.png";
    const [isContentOpen, setIsContentOpen] = useState(new Array(accodianUIConfig.length).fill(false));
    const [showTooltip, setShowTooltip] = useState(new Array(accodianUIConfig.length).fill(false));
    const [isModalVisible, setIsModalVisible] = useState(false);
    const openContents = (index) => {
        setIsContentOpen(prev => {
            const newArray = [...prev];
            newArray[index] = !newArray[index];
            return newArray;
        });
    }

    // const handleDeleteAccodian = (configType, listType, id) => {
    //     if (configType === 'basicSettingData') {
    //         const promptDataDeployed = JSON.parse(sessionStorage.getItem('prompt-data-deployed'));
    //         const payload = listType === 'company' ? promptDataDeployed.basicSettingData[0] : promptDataDeployed.basicSettingData[1];
    //         payload.id = id;
    //         payload.activated = Boolean(false);
    //         payload.condition = '';

    //         updateCustomPrompt(configType, payload);
    //         setTimeout(() => {
    //             setPromptDataUpdateState(true);
    //         }, 100);
    //     } else {
    //         deleteCustomPrompt(id)
    //             .then(res => {
    //                 setTimeout(() => {
    //                     setPromptDataUpdateState(true);
    //                 }, 100);
    //             })
    //             .catch(err => {
    //                 console.log('err', err);
    //             })
    //     }

    // }

    const AccodianEditor = (props) => {
        const { setPromptDataUpdateState } = props;
        const { configType, configList, listIndex } = JSON.parse(sessionStorage.getItem('clickedListInfo'));
        const promptDataDeployed = JSON.parse(sessionStorage.getItem('prompt-data-deployed'));
        const tempInput = JSON.parse(sessionStorage.getItem('temp-input')) ? JSON.parse(sessionStorage.getItem('temp-input')) : {};
        const [conditionInput, setConditionInput] = useState(tempInput.condition ? tempInput.condition : (promptDataDeployed[configType][listIndex]?.condition ? promptDataDeployed[configType][listIndex]?.condition : ''));
        const [answerInput, setAnswerInput] = useState(tempInput.answer ? tempInput.answer : (promptDataDeployed[configType][listIndex]?.answer ? promptDataDeployed[configType][listIndex]?.answer : ''));
        const [enableSave, setEnableSave] = useState(false);

        const handleSavePromptData = () => {
            let payload;
            if (promptDataDeployed[configType].length === listIndex) {
                payload = customPromptDataInitial[configType];
                payload.condition = conditionInput;
                payload.answer = answerInput;
                promptDataDeployed[configType].push(payload);
                updateCustomPrompt(configType, payload);
            } else {
                const payload = promptDataDeployed[configType][listIndex];
                payload.condition = conditionInput;
                payload.answer = answerInput;
                promptDataDeployed[configType][listIndex] = payload;
                updateCustomPrompt(configType, payload);
            }
        }

        useEffect(() => {
            if (configType === 'basicSettingData') {
                setEnableSave(conditionInput.length > 0);
            } else {
                setEnableSave(conditionInput.length > 0 && answerInput.length > 0);
            }
        }, [conditionInput, answerInput])

        return (
            <>
                <div className={unitsStyle.SquaredAccodianList__ContentWrapper}>
                    <div>
                        <label className="h11 fc-gray-700">{configList.condition.inputLabel}</label>
                        {
                            configList.recommendedText.length > 0 &&
                            <span className={`h11 ${configList.recommended ? 'fc-prim-800' : 'fc-gray-500'}`}>{' '}[{configList.recommendedText}]</span>
                        }
                    </div>
                    <div className="spacing-8" />
                    <textarea
                        placeholder={configList.condition.placeholder}
                        value={conditionInput}
                        className={`${unitsStyle.SquaredAccodianList__Textarea} textarea`}
                        style={{ height: configList.condition.height }}
                        onChange={(e) => {
                            setConditionInput(e.target.value);
                            sessionStorage.setItem('temp-input', JSON.stringify({
                                ...tempInput,
                                condition: e.target.value,
                            }));
                        }}
                        maxLength={configList.condition.textLimit}
                    />
                    {
                        configList.answer.visible &&
                        <>
                            <div className='spacing-16' />
                            <div>
                                <label className="h11 fc-gray-700">{configList.answer.inputLabel}</label>
                            </div>
                            <div className="spacing-8" />
                            <textarea
                                placeholder={configList.answer.placeholder}
                                value={answerInput}
                                className={`${unitsStyle.SquaredAccodianList__Textarea} textarea`}
                                style={{ height: configList.answer.height }}
                                onChange={(e) => {
                                    setAnswerInput(e.target.value);
                                    sessionStorage.setItem('temp-input', JSON.stringify({
                                        ...tempInput,
                                        answer: e.target.value,
                                    }));
                                }}
                                maxLength={configList.answer.textLimit}
                            />
                        </>
                    }
                    <div className='spacing-8' />
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                        <span className='h13 fc-gray-700'>{answerInput.length}</span><span className='h13 fc-gray-500'>/{configList.answer.textLimit}</span>
                    </div>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                    <button
                        className={unitsStyle.SquaredAccodianList__Button__cancel}
                        onClick={() => {
                            const promptDataDeployed = JSON.parse(sessionStorage.getItem('prompt-data-deployed'));
                            setConditionInput(promptDataDeployed[configType][listIndex]?.condition || '');
                            setAnswerInput(promptDataDeployed[configType][listIndex]?.answer || '');
                            setMode({
                                ...mode,
                                [configType]: 'list',
                                isEditing: false,
                            });
                            sessionStorage.removeItem('temp-input');
                        }}
                    >
                        <p className='h8 pointer fc-gray-500'>취소하기</p>
                    </button>
                    <div className="spacing-8" />
                    <button
                        className={enableSave ? unitsStyle.SquaredAccodianList__Button__applied : unitsStyle.SquaredAccodianList__Button__applied__disabled}
                        onClick={() => {
                            if (!enableSave) return;
                            setMode({
                                ...mode,
                                [configType]: 'list',
                                isEditing: false,
                            });
                            handleSavePromptData();
                            setTimeout(() => {
                                setPromptDataUpdateState(true);
                            }, 500);
                            sessionStorage.removeItem('temp-input');
                        }}
                    >
                        <p className={`h8 pointer ${enableSave ? 'fc-prim-800' : 'fc-gray-500'}`}>적용하기</p>
                    </button>
                </div>
            </>
        )
    };

    const AccodianList = (props) => {
        const { configType, configLists, setMode, setIsDeleteModalVisible, setIsModalVisible, setDeleteListId, setDeleteListType } = props;
        const promptDataDeployed = JSON.parse(sessionStorage.getItem('prompt-data-deployed'));
        // const [deleteListId, setDeleteListId] = useState(null);

        return (
            configType === 'basicSettingData' ?
                <>
                    {
                        promptDataDeployed[configType].map((list, index) => {
                            return (
                                list.condition.length > 0 &&
                                <>
                                    {/* {
                                        isDeleteModalVisible && list.id === deleteListId &&
                                        <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1000, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <ConfirmModal
                                                title={'정말 삭제하시겠습니까?'}
                                                subText={'프롬프트를 삭제하면 되돌릴 수 없습니다'}
                                                submitFunc={() => {
                                                    handleDeleteAccodian(configType, list.type, list.id)
                                                }}
                                                cancelFunc={() => setIsDeleteModalVisible(false)}
                                                setIsModalVisible={setIsDeleteModalVisible}
                                                confirmText={'삭제하기'}
                                                cancelText={'취소하기'}
                                                buttonColor={'#EC3E48'}
                                            />
                                        </div>
                                    } */}
                                    <div className={unitsStyle.SquaredAccodianList__AccodianList__Container}>
                                        <div
                                            className={unitsStyle.SquaredAccodianList__AccodianList__TitleWrap}
                                            onClick={() => {
                                                if (mode.isEditing) {
                                                    setIsModalVisible(true);
                                                    return;
                                                };
                                                const newClickedListInfo = {
                                                    configType: configType,
                                                    configList: configLists[index],
                                                    listIndex: index,
                                                };
                                                sessionStorage.setItem('clickedListInfo', JSON.stringify(newClickedListInfo));
                                                setMode({
                                                    ...mode,
                                                    [configType]: 'editor',
                                                    isEditing: true,
                                                });
                                            }}
                                        >
                                            <p className='h8 fc-gray-700'>{configLists[index].condition.inputLabel}</p>
                                            <div className="spacing-8" />
                                            <p
                                                style={{ display: '-webkit-box', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical', textOverflow: 'ellipsis', overflow: 'hidden' }}
                                                className='h10 fc-gray-500'
                                            >
                                                {list.condition}
                                            </p>
                                        </div>
                                        <div className='spacing-16' />
                                        <img
                                            src='/img/units/trashcan-icon-g500.png'
                                            width={20} height={20}
                                            className='pointer'
                                            onClick={() => { 
                                                setDeleteListId(list.id); 
                                                setDeleteListType(list.type); 
                                                setIsDeleteModalVisible(true); 
                                            }}
                                        />
                                    </div>
                                </>
                            )
                        })
                    }
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
                        {
                            configLists.map((list, index) => {
                                if (promptDataDeployed[configType][index]?.condition.length > 0) return null;
                                return (
                                    <button
                                        className={unitsStyle.SquaredAccodianList__Button__add}
                                        onClick={() => {
                                            if (mode.isEditing) {
                                                setIsModalVisible(true);
                                                return;
                                            };
                                            const newClickedListInfo = {
                                                configType: configType,
                                                configList: list,
                                                listIndex: index,
                                            };
                                            sessionStorage.setItem('clickedListInfo', JSON.stringify(newClickedListInfo));
                                            setMode({
                                                ...mode,
                                                [configType]: 'editor',
                                                isEditing: true,
                                            });
                                        }}
                                    >
                                        <p className='h8 fc-white'>{list.condition.inputLabel} 추가하기</p>
                                        <img src='/img/units/add-icon-white.png' width={16} height={16} />
                                    </button>
                                )
                            })
                        }
                    </div>
                </> :
                <>
                    {
                        promptDataDeployed[configType].map((list, index) => {
                            return (
                                list.id !== null &&
                                <>
                                    {/* {
                                        isDeleteModalVisible && list.id === deleteListId &&
                                        <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1000, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <ConfirmModal
                                                title={'정말 삭제하시겠습니까?'}
                                                subText={'프롬프트를 삭제하면 되돌릴 수 없습니다'}
                                                submitFunc={() => {
                                                    handleDeleteAccodian(configType, list.type, deleteListId);
                                                    setDeleteListId(null);
                                                }}
                                                cancelFunc={() => setIsDeleteModalVisible(false)}
                                                setIsModalVisible={setIsDeleteModalVisible}
                                                confirmText={'삭제하기'}
                                                cancelText={'취소하기'}
                                                buttonColor={'#EC3E48'}
                                            />
                                        </div>
                                    } */}
                                    <div className={unitsStyle.SquaredAccodianList__AccodianList__Container}>
                                        <div
                                            className={unitsStyle.SquaredAccodianList__AccodianList__TitleWrap}
                                            onClick={() => {
                                                if (mode.isEditing) {
                                                    setIsModalVisible(true);
                                                    return;
                                                };
                                                const newClickedListInfo = {
                                                    configType: configType,
                                                    configList: configLists[0],
                                                    listIndex: index,
                                                };
                                                sessionStorage.setItem('clickedListInfo', JSON.stringify(newClickedListInfo));
                                                setMode({
                                                    ...mode,
                                                    [configType]: 'editor',
                                                    isEditing: true,
                                                });
                                            }}
                                        >
                                            <p
                                                style={{ display: '-webkit-box', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical', textOverflow: 'ellipsis', overflow: 'hidden' }}
                                                className='h8 fc-gray-700'
                                            >
                                                {list.condition}
                                            </p>
                                            <div className="spacing-8" />
                                            <p
                                                style={{ display: '-webkit-box', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical', textOverflow: 'ellipsis', overflow: 'hidden' }}
                                                className='h10 fc-gray-500'
                                            >
                                                {list.answer}
                                            </p>
                                        </div>
                                        <div className='spacing-16' />
                                        <img
                                            src='/img/units/trashcan-icon-g500.png'
                                            width={20} height={20}
                                            className='pointer'
                                            onClick={() => {
                                                setDeleteListId(list.id);
                                                setDeleteListType(list.type);
                                                setIsDeleteModalVisible(true)
                                            }}
                                        />
                                    </div>
                                </>
                            )
                        })
                    }
                    {
                        promptAmount[configType].current < promptAmount[configType].limit &&
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                            <button
                                className={unitsStyle.SquaredAccodianList__Button__add}
                                onClick={() => {
                                    if (mode.isEditing) {
                                        setIsModalVisible(true);
                                        return;
                                    };
                                    const newClickedListInfo = {
                                        configType: configType,
                                        configList: configLists[0],
                                        listIndex: promptDataDeployed[configType].length,
                                    }
                                    sessionStorage.setItem('clickedListInfo', JSON.stringify(newClickedListInfo));
                                    setMode({
                                        ...mode,
                                        [configType]: 'editor',
                                        isEditing: true,
                                    });
                                }}
                            >
                                <p className='h8 fc-white'>프롬프트 추가하기</p>
                                <img src='/img/units/add-icon-white.png' width={16} height={16} />
                            </button>
                        </div>
                    }
                </>
        )
    }

    const promptDataDeployed = JSON.parse(sessionStorage.getItem('prompt-data-deployed'));
    // const [clickedListInfo, setClickedListInfo] = useState({
    //     configType: '',
    //     configList: [],
    //     listIndex: 0,
    // });
    // const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    // const [deleteListId, setDeleteListId] = useState(null);
    // const [deleteListType, setDeleteListType] = useState(null);
    return (
        <div>
            {
                isModalVisible &&
                <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1000, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <InfoModal
                        title={'편집 중입니다.'}
                        subText={'다른 항목을 편집 중입니다. 편집을 완료하고 수정해주세요.'}
                        setIsModalVisible={setIsModalVisible}
                        confirmText={'확인'}
                    />
                </div>
            }
            {/* {
                isDeleteModalVisible && deleteListId &&
                <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1000, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <ConfirmModal
                        title={'정말 삭제하시겠습니까?'}
                        subText={'프롬프트를 삭제하면 되돌릴 수 없습니다'}
                        submitFunc={() => {
                            handleDeleteAccodian(clickedListInfo.configType, deleteListType, deleteListId);
                            setDeleteListId(null);
                            setDeleteListType(null);
                            // setIsDeleteModalVisible(false);
                        }}
                        cancelFunc={() => setIsDeleteModalVisible(false)}
                        setIsModalVisible={setIsDeleteModalVisible}
                        confirmText={'삭제하기'}
                        cancelText={'취소하기'}
                        buttonColor={'#EC3E48'}
                    />
                </div>
            } */}
            {
                accodianUIConfig.map((config, index) => {
                    if (config.type === 'dataConfig') return null;
                    return (
                        <>
                            <div
                                className={`${unitsStyle.SquaredAccodianList__ListWrapper} pointer`}
                                onClick={() => { openContents(index) }}
                            >
                                <div className={unitsStyle.SquaredAccodianList__Title}>
                                    <p className="h11 fc-gray-600">{config.accodianText}</p>
                                    <div className="spacing-4" />
                                    <div style={{ position: 'relative' }}>
                                        <img
                                            src='/img/units/tooltip-icon-g400.png'
                                            width={20} height={20}
                                            className='pointer'
                                            onMouseOver={() => {
                                                setShowTooltip(prev => {
                                                    const newArray = [...prev];
                                                    newArray[index] = true;
                                                    return newArray;
                                                })
                                            }}
                                            onMouseLeave={() => {
                                                setShowTooltip(prev => {
                                                    const newArray = [...prev];
                                                    newArray[index] = false;
                                                    return newArray;
                                                })
                                            }}
                                        />
                                        {
                                            showTooltip[index] &&
                                            <div style={{ position: 'absolute', top: '100%', left: '50%', transform: 'translateX(-50%)', width: 'fit-content', backgroundColor: '#222', padding: '14px', borderRadius: '10px', boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.25)', zIndex: 5 }}>
                                                {
                                                    config.tooltipText.map((text, index) => {
                                                        return (
                                                            <p
                                                                className='h10 fc-gray-400'
                                                                style={{ width: '100%', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}
                                                            >{text}</p>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                    </div>
                                    <div className='spacing-8' />
                                    <p className='h13 fc-gray-500'>{promptAmount[config.type].current}/{promptAmount[config.type].limit}</p>
                                </div>
                                <img
                                    src={isContentOpen[index] ? arrowUpUrl : arrowDownUrl} width={20} height={20}
                                    className='pointer'
                                />
                            </div>
                            {
                                isContentOpen[index] &&
                                <div className={unitsStyle.SquaredAccodianList__BottomWrapper}>
                                    {
                                        mode[config.type] === 'list' ?
                                        <AccodianList
                                            configType={config.type}
                                            configLists={config.lists}
                                            mode={mode}
                                            setMode={setMode}
                                            setIsModalVisible={setIsModalVisible}
                                            setIsDeleteModalVisible={setIsDeleteModalVisible}
                                            setDeleteListId={setDeleteListId}
                                            setDeleteListType={setDeleteListType}
                                        /> :
                                        <AccodianEditor
                                            setPromptDataUpdateState={setPromptDataUpdateState}
                                        />
                                    }
                                </div>
                            }
                        </>
                    )
                })
            }
        </div>
    )
}