import axios from "axios";
import { customPromptDataInitial } from "../../../data/promptData.js";

export const fetchCustomPrompt = async () => {
    const shopId = sessionStorage.getItem('sid');
    try {
        const response = await axios.get(
            process.env.REACT_APP_CUSTOM_PROMPT_URL + `/${shopId}`,
            {
                headers: {}
            }
        )

        const promptDataDeployed = {
            basicSettingData: response.data.basicSettingData ?
            [
                {
                id: response.data.basicSettingData.id,
                type: 'company',
                activated: response.data.basicSettingData.activated,
                condition: response.data.basicSettingData.introduction,
                answer: '',
                },
                {
                id: response.data.basicSettingData.id,
                type: 'persona',
                activated: response.data.basicSettingData.activated,
                condition: response.data.basicSettingData.persona,
                answer: '',
                }
            ]
            : customPromptDataInitial.basicSettingData,
            productSettingData: response.data.productSettingData || [],
            customerServiceSettingData: response.data.customerServiceSettingData || [],
        }
        sessionStorage.setItem('prompt-data-deployed', JSON.stringify(promptDataDeployed));

        return response.data;
    } catch (error) {
        console.error(`Error while calling fetchCustomPrompt api: ${error}`);
    }
}

export const updateCustomPrompt = async (type, payload) => {
    console.log('updateCustomPrompt', type, payload);
    const shopId = sessionStorage.getItem('sid');
    const promptDataDeployed = JSON.parse(sessionStorage.getItem('prompt-data-deployed'));
    let basicPayload = {};
    if (payload.id === "null") payload.id = null;

    if (type === 'basicSettingData') {
        basicPayload = {
            id: payload.id || null,
            activated: Boolean(payload.activated),
            introduction: payload.type === 'company' ? payload.condition : promptDataDeployed?.basicSettingData[0]?.condition,
            persona: payload.type === 'persona' ? payload.condition : promptDataDeployed?.basicSettingData[1]?.condition,
        };
        console.log('basicPayload @ updateCustomPrompt', basicPayload);
    }
    console.log('basicPayload2 @ updateCustomPrompt', basicPayload);
    const url = process.env.REACT_APP_CUSTOM_PROMPT_URL + `/${shopId}${type === 'basicSettingData' ? '/basic' : ''}`;
    try {
        const response = await axios.put(
            url,
            type === 'basicSettingData' ? basicPayload : payload,
            {
                headers: {}
            }
        )
        return response.data;
    } catch (error) {
        console.error(`Error while calling updateCustomPrompt api: ${error}`);
    }
}

export const deleteCustomPrompt = async (id) => {
    const shopId = sessionStorage.getItem('sid');
    try {
        const response = await axios.delete(
            process.env.REACT_APP_CUSTOM_PROMPT_URL + `/${shopId}/${String(id)}`,
            {
                headers : {
                    'Cache-Control' : 'no-cache, no-store, must-revalidate' ,
                    'Pragma' : 'no-cache' ,
                    'Expires' : '0'
                }     
            }
        )
        return response.data;
    } catch (error) {
        console.error(`Error while calling deleteCustomPrompt api: ${error}`);
    }
}

export const deployCustomPrompt = async () => {
    const shopId = sessionStorage.getItem('sid');
    try {
        const response = await axios.post(
            process.env.REACT_APP_CUSTOM_PROMPT_URL + `/${shopId}`,
            {},
            {
                headers: {}
            }
        )
        return response.data;
    } catch (error) {
        console.error(`Error while calling deployCustomPrompt api: ${error}`);
    }
}